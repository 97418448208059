*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    background: #0A1128;
    font-family: 'Quicksand', sans-serif;

}

body::before {
    display: block;
    content: '';
    height: 60px;
  }