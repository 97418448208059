.card-lgt{
    background: transparent;
    border: solid 2px #1282A2;
}

.card-drk{
background: transparent;
border: solid 2px #034078;
}

.skills-background{
    background: rgb(0,31,84);
    background: linear-gradient(0deg, rgba(10,17,40,1) 5%, rgba(0,31,84,1) 20%, rgba(10,17,40,1) 100%);
}
