footer{
    background: #05090f;
}

.footer-link{
    text-decoration: none;
    color: #FEFCFB;
}

.footer-link:hover{
    font-size: 20px;
    color:#1282A2 ;
}